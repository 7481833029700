[dir="rtl"] .me-2 {
  margin-right: 0 !important;
  margin-inline-end: 0.5rem;
}

a {
  text-decoration: none;
}

body.user-app {
  .pc-container {
    margin-left: 0;
  }

  .pc-header, .page-header {
    left: 0;
  }

  @media (max-width: 1024px) {
    .pc-header .pcm-logo {
      margin-left: 0;
    }
  }
}

// Alerts
//.alert-notice { @extend .alert-warning; }
//.alert-error  { @extend .alert-danger; }


.alert-notice {
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5;
}
.alert-notice .alert-link {
  color: #523e02;
}



.alert-error {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}
.alert-error .alert-link {
  color: #6a1a21;
}


ul.list-group  li  ul {
  margin-top: 20px;
}

.testimonial .avatar {
  width: 100px;
  height: 100px;
  border: 1px solid #ccc;
  padding: 5px;
  background-color: #eee;
}

@media (min-width: 576px) {
  .testimonial .avatar {
    width: 64px;
    height: 64px;
  }
}

@media (min-width: 768px) {
  .testimonial .avatar {
    width: 100px;
    height: 100px;
  }
}



label, legend {
  font-size: 1rem;
}


.form-check {
  //display: flex;
  padding-bottom: 10px;
}

.form-check .form-check-input {
  margin-right: 10px;
}

.form-check-horizontal .form-check {
  display: inline-block;
  margin-right: 1rem;
}


.badge {
  padding: 0.5em 0.85em;
  font-size: .85em;
  border-radius:0.3em;
}


.attributes-card {
  section {
    display: flex;
    align-items: center;
    
    margin-bottom: 2rem;
    border-bottom: 1px solid #f1f1f1;
    padding-bottom: 0.2rem;

    h5 {
      //margin-right: 1rem;
      margin-bottom: 0;
    }

    & > span, & > div {
      flex: 1;
      text-align: right;
    }
  }
}


.site-wrapper {
  background: #fff;
  position: relative;
  transition: transform .6s cubic-bezier(0.23, 1, 0.32, 1);
  display: flex;
  flex-direction: column;
  min-height: 100vh;



  .main-header {
    flex: 0 0 auto;
    display: flex;
    height: 4rem
  }

  @media (min-width: 994px) {
    .main-header {
      height:6rem
    }
  }

  .main-header .container {
    display: flex;
    justify-content: space-between;
    align-items: center
  }

  .main-header .brand:focus {
    outline-offset: 5px
  }

  @media (max-width: 993.98px) {
    .main-header .brand img {
      width:auto;
      height: 28px
    }
  }

  .main-header .main-nav {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center
  }

  .main-header .nav-link {
    color: #222
  }

  .main-header .log-in {
    color: #0069e0
  }

  .main-header .user-dropdown-toggle {
    color: #0069e0
  }

  @media (min-width: 994px) {
    .main-header .user-dropdown-toggle {
      max-width:7.875rem
    }
  }

  @media (min-width: 1234px) {
    .main-header .user-dropdown-toggle {
      max-width:20.25rem
    }
  }

  .main-header .user-dropdown-toggle:hover {
    text-decoration: none
  }

  .main-header .user-dropdown-toggle:focus {
    outline: none
  }

  .keyboard-user .main-header .user-dropdown-toggle:focus {
    outline: 3px solid rgba(128,187,255,0.5)
  }

  .main-header .user-dropdown-toggle::after {
    content: none
  }

  .main-header .upgrade-storage {
    margin-left: 1rem;
    font-size: .875rem;
    padding-top: .28125rem;
    padding-bottom: .28125rem
  }

  .main-header .nav-toggle {
    border: none;
    background: transparent;
    padding: .75rem 16px;
    line-height: 1;
    color: inherit;
    margin-right: -16px;
    border-radius: .375rem
  }

  @media (min-width: 568px) and (max-width: 767.98px) {
    .main-header .nav-toggle {
      position:absolute;
      top: .6875rem;
      right: calc((100% - 504px) / 2);
      transition: right .6s cubic-bezier(0.23, 1, 0.32, 1)
    }

    .nav-opened .main-header .nav-toggle {
      right: 16px
    }
  }

  @media (min-width: 768px) and (max-width: 993.98px) {
    .main-header .nav-toggle {
      position:absolute;
      top: .6875rem;
      right: calc((100% - 704px) / 2);
      transition: right .6s cubic-bezier(0.23, 1, 0.32, 1)
    }

    .nav-opened .main-header .nav-toggle {
      right: 16px
    }
  }

  .keyboard-user .main-header .nav-toggle:focus {
    box-shadow: inset 0 0 0 .125rem #0069e0
  }

  .main-header .nav-toggle svg {
    vertical-align: top
  }

  .btn-primary {
    color: #fff;
    background-color: #cc2936;
    border-color: #cc2936;
  }

  .btn-secondary {
    color: #fff;
    background-color: #0069e0;
    border-color: #0069e0;
  }


  .btn-lg,.btn-group-lg>.btn {
    font-weight: 300
  }

  .btn-lg,.btn-group-lg>.btn {
    padding: .3125rem 1rem;
    font-size: 1.5rem;
    line-height: 1.5;
    border-radius: .1875rem
  }


  .main-header .signup {
    margin-left: 1rem;
    font-size: .875rem;
    padding-top: 0.28125rem;
    padding-bottom: 0.28125rem;
  }



  .jumbotron {
    padding: 4rem 2rem;
    margin-bottom: 4rem;
    border-radius: 0.3rem;
  }

  .jumbotron-fluid {
    padding-right: 0;
    padding-left: 0;
    border-radius: 0;
  }

  .jumbotron {
    text-align: center;
    color: #212529;
    //background-color: #2c3038;
    padding-top: 47px;
    padding-bottom: 26px;
    margin-bottom: 0;

    h1 {
      color: #fff;
    }
  }

  @media (min-width: 768px) {
    .jumbotron {
      padding-top:101px;
      padding-bottom: 101px;
      //background: url("../images/jumbotron.svg") no-repeat center 18px #2c3038
    }
  }

  .jumbotron .display-1 {
    margin-bottom: 12px;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased
  }

  @media (min-width: 768px) {
    .jumbotron .display-1 {
      margin-bottom:23px
    }
  }

  .jumbotron .lead {
    margin: 0 auto 24px;
    max-width: 420px
  }

  @media (min-width: 768px) {
    .jumbotron .lead {
      margin-bottom:38px;
      max-width: none
    }
  }

  .jumbotron .download {
    margin-bottom: 20px
  }

  .jumbotron .platforms {
    font-size: .875rem;
    margin-bottom: 0
  }

  .jumbotron .zotero-bib {
    font-size: .875rem;
    margin-top: .875rem
  }

  @media (max-width: 767.98px) {
    .jumbotron .zotero-bib {
      display:none
    }
  }

  .jumbotron .zotero-bib a {
    color: inherit;
    text-decoration: underline
  }


  .jumbotron .lead {
    font-size: 1.25rem
  }

  @media (min-width: 768px) {
    .jumbotron .lead {
      font-size:1.5rem
    }
  }


}

.auth-wrapper {
  background: #0dacb4;
}

.auth-wrapper .auth-content:not(.container) {
  width: auto;
}

.auth-wrapper .auth-content:not(.container) .card-body {
  padding: 40px 35px;
  min-width: 400px;
}

.pc-header .user-avtar {
  margin: 0;
  border-radius: 50%;
  width: 35px;
  height: 35px;
}

.pc-sidebar {
  //background: cornflowerblue;
}


.ts-dropdown [data-selectable] .highlight {
  margin: 0 !important;
  padding: 0 !important;
}


.comments .comment {
  //border: 1px solid #ccc;
  //padding: 10px;
  //border-radius: 10px;
  //margin-bottom: 2rem;
}


.blacklisted {
  border: 1px solid #efa5a5;
  background: #fff4f4;
}


[dir="rtl"] {

  .text-end {
    text-align: left !important;
  }

  .text-start {
    text-align: right !important;
  }

  .woot-widget-bubble.woot-widget--expanded div {
    padding-inline-end: 20px !important;
    padding-right: 0 !important;
  }

  .woot-widget-bubble.woot-elements--right {
    left: 20px;
    right: auto;
  }

  .nav {
    padding-right: 0;
  }



  .attributes-card {
    section {
      & > span, & > div {
        flex: 1;
        text-align: left;
      }
    }
  }
}




.book-first-page.rtl {
  direction: rtl;
  text-align: right;
}

.book-first-page.ltr {
  direction: ltr;
  text-align: left;
}


.draft-item.liked .toggle-like-btn {
  background-color: #a1f9a1;
}
