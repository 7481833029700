
body {
  --wp--preset--color--black: #000000;
  --wp--preset--color--light-green-cyan: #7bdcb5;
  --wp--preset--color--vivid-green-cyan: #00d084;
  --wp--preset--color--zeever-primary: #212529;
  --wp--preset--color--zeever-secondary: #358781;
  --wp--preset--color--zeever-third: #121212;
  --wp--preset--color--zeever-bodytext: #6c757d;
  --wp--preset--color--zeever-bgsoft: #ffffff;
  --wp--preset--color--zeever-border: #363636;

  --wp--preset--font-size--tiny: 16px;
  --wp--preset--font-size--small: 18px;
  --wp--preset--font-size--medium: 20px;
  --wp--preset--font-size--large: 24px;
  --wp--preset--font-size--x-large: 42px;
  --wp--preset--font-size--tiny: 16px;
  --wp--preset--font-size--normal: 20px;
  --wp--preset--font-size--extra-large: 40px;
  --wp--preset--font-size--huge: 96px;
  --wp--preset--font-size--gigantic: 144px;
  --wp--preset--font-size--heading-1: clamp(42px, 5.2778vw, 76px );
  --wp--preset--font-size--heading-2: clamp(28px, 3.334vw, 48px);
  --wp--preset--font-size--heading-3: clamp(22px, 1.667vw, 24px);
  --wp--preset--font-size--heading-4: clamp(20px, 1.528vw, 22px);
  --wp--preset--font-size--heading-5: clamp(18px, 1.389vw, 20px);
  --wp--preset--font-size--heading-6: clamp(14px, 1.112vw, 16px);
}

.has-tiny-font-size {
  font-size: var(--wp--preset--font-size--tiny) !important;
}

.has-zeever-primary-color {
  color: var(--wp--preset--color--zeever-primary) !important;
}

.has-zeever-secondary-background-color {
  background-color: var(--wp--preset--color--zeever-secondary) !important;
}

.has-zeever-secondary-color {
  color: var(--wp--preset--color--zeever-secondary) !important;
}

.has-zeever-bodytext-color {
  color: var(--wp--preset--color--zeever-bodytext) !important;
}

.has-heading-3-font-size {
  font-size: var(--wp--preset--font-size--heading-3) !important;
}

.has-zeever-bgsoft-background-color {
  background-color: var(--wp--preset--color--zeever-bgsoft) !important;
}

.has-black-background-color {
  background-color: var(--wp--preset--color--black) !important;
}

.about-background-color {
  background-color: #d3e2e2 !important;
}

.basic-header-bg-color {
  background-color: #d3e2e2 !important;
}

.has-heading-1-font-size {
  font-size: var(--wp--preset--font-size--heading-1) !important;
}

.has-heading-2-font-size {
  font-size: var(--wp--preset--font-size--heading-2) !important;
}

body .is-layout-flex {
  display: flex;
  gap: 24px;
}

.wp-block-columns {
  display: flex;
  margin-bottom: 1.75em;
  flex-wrap: wrap;
  align-items: normal;
}
.wp-block-columns .wp-block-column {
  flex-basis: 0;
  flex-grow: 1;

  min-width: 0;
  word-break: break-word;
  overflow-wrap: break-word;
}

.is-style-lineseparator {
  display: flex;
  align-items: center;
  gap: 10px;
}

.is-style-lineseparator:before {
  content: "";
  width: 70px;
  margin-right: 20px;
  border-top-width: 3px;
  border-top-color: brown;
  border-top-style: solid;
  display: inline-block;
}

.is-style-customborderbottomhover {
  border-bottom: 5px solid #FFFFFF;
}

.wp-block-image {
  margin: 0 0 1em;
}

.wp-block-image img {
  height: auto;
  max-width: 100%;
  vertical-align: bottom;
}


.wp-block-cover, .wp-block-cover-image {
  position: relative;
  background-position: 50%;
  min-height: 430px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
  box-sizing: border-box;
}

.wp-block-cover .wp-block-cover__background {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  opacity: .5;
}

.wp-block-cover .wp-block-cover__background.has-background-dim.has-background-dim-30 {
  opacity: .3;
}

.wp-block-cover .wp-block-cover__background.has-background-dim.has-background-dim-90 {
  opacity: .9;
}

.wp-block-cover .has-background-dim:not([class*=-background-color]) {
  background-color: #000;
}

.wp-block-cover .wp-block-cover__background.has-background-dim.has-background-dim-0 {
  opacity: 0;
}

.wp-block-cover-image .wp-block-cover__inner-container,
.wp-block-cover .wp-block-cover__inner-container {
  width: 100%;
  z-index: 1;
  color: #fff;
}

.wp-block-cover__image-background {
  z-index: 0;
}

.wp-block-cover .wp-block-cover__image-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  max-width: none;
  max-height: none;
  object-fit: cover;
  outline: none;
  border: none;
  box-shadow: none;
}

.wp-block-post-template {
  margin-top: 0;
  margin-bottom: 0;
  max-width: 100%;
  list-style: none;
  padding: 0;
}

.wp-block-post-template.is-flex-container {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  gap: 1.25em;
}

//Social

body .is-layout-flow > .aligncenter {
  margin-left: auto !important;
  margin-right: auto !important;
}

.is-style-iconsborderhover.wp-block-social-links {
  gap: 20px;
}
.wp-block-social-links.wp-container-80, .wp-block-buttons.wp-container-81 {
  justify-content: center;
}
.wp-block-social-links.aligncenter {
  justify-content: center;
  display: flex;
}
.wp-block-social-links, .wp-block-social-links.has-normal-icon-size {
  font-size: 24px;
}

.is-style-iconsborderhover.wp-block-social-links .wp-block-social-link {
  padding: 8px;
}

.is-style-iconsborderhover.wp-block-social-links .wp-block-social-link {
  font-size: 20px;
  background-color: #ffffff00;
  border: 2px solid var(--wp--preset--color--zeever-secondary);
  color: #ffffff;
}

.wp-block-social-link {
  display: block;
  border-radius: 9999px;
  transition: transform .1s ease;
  height: auto;
}

.wp-block-social-links .wp-social-link a {
  padding: .25em
}

.wp-block-social-links .wp-social-link svg {
  width: 1em;
  height: 1em
}


.wp-block-social-link:hover {
  transform: scale(1.1)
}

.wp-block-social-links .wp-social-link a {
  padding: 0.25em;
}
.wp-block-social-links .wp-social-link a, .wp-block-social-links .wp-social-link a:hover {
  text-decoration: none;
  border-bottom: 0;
  box-shadow: none;
}
.wp-block-social-link a {
  align-items: center;
  display: flex;
  line-height: 0;
  transition: transform .1s ease;
}

.wp-block-social-links .wp-block-social-link .wp-block-social-link-anchor,
.wp-block-social-links .wp-block-social-link .wp-block-social-link-anchor:hover {
  color: currentColor;
  fill: currentColor;
}

.wp-block-social-links .wp-social-link a, .wp-block-social-links .wp-social-link a:hover {
  text-decoration: none;
  border-bottom: 0;
  box-shadow: none;
}

.screen-reader-text {
  border: 0;
  clip: rect(1px,1px,1px,1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal!important;
}

.wp-block-separator {
  height: 2px;
  opacity: 1;
  border: none;
  width: 100px;
  margin: 0 auto;
}


@media (max-width: 781px) {
  .wp-block-columns:not(.is-not-stacked-on-mobile)>.wp-block-column {
    flex-basis: 100%!important;
  }
}
